import NavBar from "../components/common/NavBar";
import { Col, Container, Image, Row, Button, Card, link } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { orderItemState, shoppingItemState } from "../states/atoms";
import styled from "styled-components";
import Footer from "../components/common/Footer";
import LectureDetail from "../components/program/LectureDetail";
import { StyledLectureDetailContainer } from "../components/program/LectureDetail.styles.js";

import CheckCircle from "../icon/check-circle.svg";
import ArrowRight from "../icon/arrow-right-short.svg";
import Suitcase from "../icon/suitcase-three.svg";
import StarFill from "../icon/star-fill.svg";
import StarHalf from "../icon/star-half.svg";

export default function About() {
  const { t } = useTranslation();

  const getStartedRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();

  const [lecture, setLecture] = useState([]);

  const [orderItem, setOrderItem] = useRecoilState(orderItemState);
  const [shoppingItem, setShoppingItem] = useRecoilState(shoppingItemState);
  const getDescRef = useRef(null);

  const scrollToGetStarted = useCallback(() => {
    if (getStartedRef.current) {
      getStartedRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [getStartedRef]);

  const onClickBuyNow = useCallback(() => {
    scrollToGetStarted();
  }, [lecture, navigate, setOrderItem]);

  //다운로드 url 추가전에 임시기능
  const onClickDownNow = useCallback(() => {
    scrollToLectureDetail();
  }, [lecture, navigate, setOrderItem]);

  const scrollToLectureDetail = useCallback(() => {
    if (getDescRef.current) {
      getDescRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [getDescRef]);

  const onClickEnrollNow = useCallback((plan = "monthly") => {
    if (!localStorage.getItem("accessToken")) {
      if (window.confirm("로그인 후 이용해주세요.")) {
        navigate("/auth/sign-in");
        return;
      } else {
        // 사용자가 "취소"를 눌렀을 때 아무 동작도 하지 않도록 처리
        return;
      }
    }

    setOrderItem([lecture]);
    navigate("/order", { state: { subplan: plan } });
  });

  useEffect(() => {
    AOS.init();
  });

  return (
    <>
      <div className="bg-white">
        {/* hero block */}
        <HeroBlock t={t} />
        {/* discount block */}
        <BannerBox>
          <Container
            fluid
            className="flex multi-bg-box"
            style={{
              color: "#fff",
              backgroundColor: "#171a53",
              height: "490px",
              paddingTop: "190px",
            }}
          >
            <Container className="text-center" style={{ marginBottom: "50px" }}>
              <div className="fw-bold mb-4" style={{ lineHeight: "1", textAlign: "center" }}>
                <p>{t("WhyUdacity_Header_Title1")}</p>
                <p>{t("WhyUdacity_Header_Title2")}</p>
              </div>
              <UsButton href="/auth/sign-in">
                Get Started
                <span style={{ marginLeft: "8px" }}>
                  <svg
                    viewBox="0 0 32 32"
                    focusable="false"
                    aria-hidden="true"
                    style={{ width: "24px", height: "24px" }}
                  >
                    <path
                      d="M16.293 7.293a1 1 0 011.414 0l8 8a1 1 0 010 1.414l-8 8a1 1 0 01-1.414-1.414L22.585 17H7a1 1 0 01-.993-.883L6 16a1 1 0 011-1h15.585l-6.292-6.293a1 1 0 01-.083-1.32z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </UsButton>
            </Container>
          </Container>
        </BannerBox>
      </div>
      {/* Upskilling block */}
      <UdacityUpskilling style={{ paddingBottom: "50px" }}>
        <Container data-aos="fade-up">
          <Row>
            <Col xs={12} md={6} style={{ display: "flex", alignItems: "center" }}>
              <ContentImage>
                <Image
                  src="/img/whyudacity/SebastianThrun.webp"
                  style={{ objectFit: "cover", maxWidth: "100%", height: "300px", width: "300px" }}
                />
                <ImageCaption className="first-caption">
                  {t("WhyUdacity_Upskilling_Caption1")}
                </ImageCaption>
                <ImageCaption>
                  <Image
                    className="caption-logo-image"
                    src="/img/whyudacity/googlelogo.svg"
                    style={{ height: "14px", width: "15px", margin: "4px" }}
                  />
                  {t("WhyUdacity_Upskilling_Caption2")}
                </ImageCaption>
                <div class="stack-icons">
                  <i class="far fa-circle"></i>
                  <i class="fas fa-angle-up"></i>
                </div>
              </ContentImage>
              <ContentImage>
                <Image
                  src="/img/whyudacity/PeterNorvig.png"
                  style={{ objectFit: "cover", maxWidth: "100%", height: "300px", width: "300px" }}
                />
                <ImageCaption className="first-caption">
                  {t("WhyUdacity_Upskilling_Caption3")}
                </ImageCaption>
                <ImageCaption>
                  <Image
                    className="caption-logo-image"
                    src="/img/whyudacity/googlelogo.svg"
                    style={{ height: "14px", width: "15px", margin: "4px" }}
                  />
                  {t("WhyUdacity_Upskilling_Caption4")}
                </ImageCaption>
              </ContentImage>
            </Col>
            <Col xs={12} md={6} style={{ display: "flex", alignItems: "center" }}>
              <div>
                <UdacityUpskillingTitle>
                  <span
                    className=" display-6 fw-bold"
                    style={{ color: "#2015FF", lineHeight: "1.4" }}
                  >
                    {t("WhyUdacity_Upskilling_Title1")}
                  </span>
                  <span className="d-block display-6 fw-bold  mb-4">
                    {t("WhyUdacity_Upskilling_Title2")}
                  </span>
                </UdacityUpskillingTitle>
                <div
                  className="mb-4"
                  style={{
                    fontSize: "15px",
                    textAlign: "left",
                    fontWeight: "normal",
                    width: "100%",
                  }}
                >
                  <span className="d-block">{t("WhyUdacity_Upskilling_Contents1")}</span>
                  <span className="d-block">{t("WhyUdacity_Upskilling_Contents2")}</span>
                  <span className="d-block mb-3">{t("WhyUdacity_Upskilling_Contents3")}</span>
                  <span className="d-block">{t("WhyUdacity_Upskilling_Contents4")}</span>
                  <span className="d-block mb-4">{t("WhyUdacity_Upskilling_Contents5")}</span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </UdacityUpskilling>
      {/* Performance block */}
      <UdacityPerformanceContents data-aos="fade-up">
        <ContentBox>
          <div class="Korea-container">
            <div class="rectangle">
              <div class="Korea-title">{t("WhyUdacity_Performance_Title1")}</div>
              <div className="Korea-content">
                {t("WhyUdacity_Performance_Contents1")}
                <br />
                {t("WhyUdacity_Performance_Contents2")}
              </div>
            </div>
            <div class="line"></div>
            <div class="rectangle">
              <div class="Korea-title">{t("WhyUdacity_Performance_Title2")}</div>
              <div className="Korea-content">{t("WhyUdacity_Performance_Contents3")}</div>
            </div>
            <div class="line"></div>
            <div class="rectangle">
              <div class="Korea-title">{t("WhyUdacity_Performance_Title3")}</div>
              <div className="Korea-content">
                {t("WhyUdacity_Performance_Contents4")}
                <br />
                {t("WhyUdacity_Performance_Contents5")}
              </div>
            </div>
            <div class="line"></div>
            <div class="rectangle">
              <div class="Korea-title">{t("WhyUdacity_Performance_Title4")}</div>
              <div class="Korea-content">{t("WhyUdacity_Performance_Contents6")}</div>
            </div>
          </div>
        </ContentBox>
      </UdacityPerformanceContents>
      {/* Excellent Quality Contents block */}
      <div data-aos="fade-up">
        <PriceSubscriptionContainer style={{ paddingBottom: "80px" }}>
          <Row className="align-items-center article-block">
            <Col xs={12} md={6}>
              <div>
                <span className="d-block display-6 fw-bold" style={{ color: "#2015FF" }}>
                  {t("WhyUdacity_Excellent_Quality_Title1")}
                </span>
                <span className="d-block display-6 fw-bold" style={{ lineHeight: "1.4" }}>
                  {t("WhyUdacity_Excellent_Quality_Title2")}
                </span>
                <span
                  className=" display-6 fw-bold mb-4"
                  style={{
                    backgroundColor: "#2015FF",
                    color: "#fff",
                    lineHeight: "1.4",
                  }}
                >
                  {t("WhyUdacity_Excellent_Quality_Title3")}
                </span>
                <div
                  className="mb-4"
                  style={{
                    fontSize: "15px",
                    textAlign: "left",
                    fontWeight: "normal",
                    width: "80%",
                    marginTop: "30px",
                  }}
                >
                  <span className="d-block">{t("WhyUdacity_Excellent_Quality_Contents1")}</span>
                  <span className="d-block mb-4">
                    {t("WhyUdacity_Excellent_Quality_Contents2")}
                  </span>
                </div>
                <AccountButton href="/programs/25/lectures">
                  {t("WhyUdacity_Excellent_Quality_Button")}
                </AccountButton>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <ContentImage>
                <Image
                  src="/img/whyudacity/whycontents.webp"
                  style={{ objectFit: "cover", maxWidth: "100%" }}
                />
              </ContentImage>
            </Col>
            <MainImage>
              <Image
                src="/img/cocreated.png"
                style={{ objectFit: "cover", maxWidth: "100%", marginTop: "25px" }}
              />
            </MainImage>
          </Row>
        </PriceSubscriptionContainer>
      </div>
      {/* Mentoring block */}
      <div data-aos="fade-up" className="bg-light">
        <PriceSubscriptionContainer style={{ paddingBottom: "80px", paddingTop: "40px" }}>
          <Row className="align-items-center article-block">
            <Col xs={12} md={6}>
              <MainImage>
                <Image
                  src="/img/mentoring.webp"
                  style={{ objectFit: "cover", maxWidth: "100%", marginTop: "25px" }}
                />
              </MainImage>
            </Col>
            <Col xs={12} md={6}>
              <div>
                <span
                  className="d-block display-6 fw-bold"
                  style={{ color: "#2015FF", marginTop: "25px", lineHeight: "1.4" }}
                >
                  {t("WhyUdacity_Mentoring_Title1")}
                </span>
                <span className="d-block display-6 fw-bold mb-4">
                  {t("WhyUdacity_Mentoring_Title2")}
                  <span style={{ color: "#fff", backgroundColor: "#2015FF" }}>
                    {t("WhyUdacity_Mentoring_Title3")}
                  </span>
                </span>
                <div
                  className="mb-4"
                  style={{
                    fontSize: "15px",
                    textAlign: "left",
                    fontWeight: "normal",
                  }}
                >
                  <span className="d-block mb-4">{t("WhyUdacity_Mentoring_Contents1")}</span>
                  <span className="d-block">{t("WhyUdacity_Mentoring_Contents2")}</span>
                  <span className="d-block">{t("WhyUdacity_Mentoring_Contents3")}</span>
                </div>
              </div>
            </Col>
          </Row>
          <div className="partner-line"></div>
          <span
            className="d-flex justify-content-center"
            style={{ color: "#2015FF", textAlign: "center" }}
          >
            {t("WhyUdacity_Mentoring_Partner")}
          </span>
          <div className="partner-container">
            <Image src="/img/whyudacity/google.svg" />
            <Image src="/img/whyudacity/facebook.svg" />
            <Image src="/img/whyudacity/amazon.svg" />
            <Image src="/img/whyudacity/adobe.svg" />
            <Image src="/img/whyudacity/microsoft.svg" />
            <Image src="/img/whyudacity/qualcomm.svg" />
          </div>
        </PriceSubscriptionContainer>
      </div>
      {/* working project block */}
      <PriceSubscriptionContainer style={{ paddingBottom: "80px", paddingTop: "40px" }}>
        <Row data-aos="fade-up">
          <Col
            xs={12}
            md={6}
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <div>
              <span
                className="display-6 fw-bold mb-4"
                style={{
                  backgroundColor: "#2015FF",
                  color: "#fff",
                  textAlign: "center",
                  lineHeight: "1.4",
                }}
              >
                {t("WhyUdacity_Project_Title1")}
              </span>
              <span className="d-block display-6 fw-bold mb-4">
                {t("WhyUdacity_Project_Title2")}
              </span>
              <div
                className="mb-4"
                style={{
                  fontSize: "15px",
                  textAlign: "left",
                  fontWeight: "normal",
                  width: "80%",
                }}
              >
                <span className="d-block">{t("WhyUdacity_Project_Contents1")}</span>
                <span className="d-block mb-4">{t("WhyUdacity_Project_Contents2")}</span>
                <span className="d-block">{t("WhyUdacity_Project_Contents3")}</span>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <ProjectImage>
              <Image
                src="/img/whyudacity/project_image.webp"
                style={{ objectFit: "cover", maxWidth: "100%" }}
              />
            </ProjectImage>
          </Col>
        </Row>
      </PriceSubscriptionContainer>
      {/* review block */}
      <Container fluid className="d-flex align-items-center bg-light">
        <Container data-aos="fade-up" className="p-3 p-md-5">
          <PriceSubscriptionContainer>
            <div className="review-container mb-4">
              <Image src="/img/whyudacity/review1.webp" />
              <Image src="/img/whyudacity/review2.webp" />
              <Image src="/img/whyudacity/review3.webp" />
              <Image src="/img/whyudacity/review4.webp" />
              <Image src="/img/whyudacity/review5.webp" />
              <Image src="/img/whyudacity/review6.webp" />
            </div>
            <Col
              className="review-col-container"
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <div style={{ padding: "10px" }}>
                <Image src="/img/whyudacity/review_1.svg" />
                <div>
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarHalf} alt="ReviewsImage" />
                </div>
                <a
                  className="review-button"
                  href="https://www.facebook.com/Udacity/reviews"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontWeight: "bold" }}
                >
                  <p>
                    {t("WhyUdacity_Reviews_Count1")}

                    <span>
                      <img
                        src="/img/arrow-more.svg"
                        alt="Arrow Right"
                        style={{ width: "20px", height: "20px" }}
                      />
                    </span>
                  </p>
                </a>
              </div>
              <div style={{ padding: "10px" }}>
                <Image src="/img/whyudacity/review_2.svg" />
                <div>
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarHalf} alt="ReviewsImage" />
                </div>
                <a
                  className="review-button"
                  href="https://www.coursereport.com/schools/udacity"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontWeight: "bold" }}
                >
                  <p>
                    {t("WhyUdacity_Reviews_Count2")}
                    <span>
                      <img
                        src="/img/arrow-more.svg"
                        alt="Arrow Right"
                        style={{ width: "20px", height: "20px" }}
                      />
                    </span>
                  </p>
                </a>
              </div>
              <div style={{ padding: "10px" }}>
                <Image src="/img/whyudacity/review_3.svg" />
                <div>
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarHalf} alt="ReviewsImage" />
                </div>
                <a
                  className="review-button"
                  href="https://www.switchup.org/bootcamps/udacity"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontWeight: "bold" }}
                >
                  <p>
                    {t("WhyUdacity_Reviews_Count3")}
                    <span>
                      <img
                        src="/img/arrow-more.svg"
                        alt="Arrow Right"
                        style={{ width: "20px", height: "20px" }}
                      />
                    </span>
                  </p>
                </a>
              </div>
              <div style={{ padding: "10px" }}>
                <Image src="/img/whyudacity/review_4.svg" />
                <div>
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarHalf} alt="ReviewsImage" />
                </div>
                <a
                  className="review-button"
                  href="https://www.trustpilot.com/review/udacity.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontWeight: "bold" }}
                >
                  <p>
                    {t("WhyUdacity_Reviews_Count4")}
                    <span>
                      <img
                        src="/img/arrow-more.svg"
                        alt="Arrow Right"
                        style={{ width: "20px", height: "20px" }}
                      />
                    </span>
                  </p>
                </a>
              </div>
              <div style={{ padding: "10px" }}>
                <Image src="/img/whyudacity/review_5.svg" />
                <div>
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarFill} alt="ReviewsImage" />
                  <img src={StarHalf} alt="ReviewsImage" />
                </div>
                <a
                  className="review-button"
                  href="https://www.g2.com/products/udacity/reviews"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontWeight: "bold" }}
                >
                  <p>
                    {t("WhyUdacity_Reviews_Count5")}
                    <span>
                      <img
                        src="/img/arrow-more.svg"
                        alt="Arrow Right"
                        style={{ width: "20px", height: "20px" }}
                      />
                    </span>
                  </p>
                </a>
              </div>
            </Col>
          </PriceSubscriptionContainer>
        </Container>
      </Container>
      {/* additional benefits block */}
      <StyledLectureDetailContainer style={{ marginTop: 0, marginBottom: 0 }}>
        <div ref={getDescRef} className="price-container">
          <div className="title">
            <div className="sub">{t("aboutPage_additional_benefits_price")}</div>
          </div>
          <div className="contents" style={{ gap: "10px" }}>
            <div className="us-container one-monthly-sub">
              <div className="title">
                <div className="desc">
                  <div>{t("one-monthly-sub")}</div>
                  <span className="discount">Save 10%</span>
                </div>
              </div>
              <div className="price">
                <del
                  style={{
                    color: "#696969",
                    fontWeight: "300",
                    fontSize: "2.8rem",
                    marginBottom: "10px",
                  }}
                >
                  {t("one-monthly-sub-price")}
                </del>
                <div>{t("one-monthly-sub-discounted-price")}</div>
              </div>
              <hr className="divider" />
              <ul className="benefits-list">
                <li style={{ fontWeight: "bold" }}>
                  <img src={CheckCircle} alt="Check Circle" />
                  {t("benefits-list1")}
                </li>
                <li>
                  <img src={CheckCircle} alt="Check Circle" />
                  {t("benefits-list2")}
                </li>
                <li>
                  <img src={CheckCircle} alt="Check Circle" />
                  {t("benefits-list3")}
                </li>
                <li>
                  <img src={CheckCircle} alt="Check Circle" />
                  {t("benefits-list4")}
                </li>
                <li>
                  <img src={CheckCircle} alt="Check Circle" />
                  {t("benefits-list5")}
                </li>
              </ul>
              <div className="button-container">
                <Button
                  onClick={() => {
                    onClickEnrollNow("monthly");
                  }}
                >
                  {t("Enroll Now")}
                  <img
                    src={ArrowRight}
                    alt="Arrow Right"
                    style={{ filter: "brightness(0) invert(1)" }}
                  />
                </Button>
              </div>
            </div>
            <div className="us-container four-monthly-sub">
              <div className="title">
                <div className="desc">
                  <div>{t("four-monthly-sub")}</div>
                  <span className="discount">Save 25%</span>
                </div>
                <div className="sub">{t("four-monthly-sub-time")}</div>
              </div>
              <div className="price">
                <del
                  style={{
                    color: "#696969",
                    fontWeight: "300",
                    fontSize: "2.8rem",
                    marginBottom: "10px",
                  }}
                >
                  {t("four-monthly-sub-original-price")}
                </del>
                <div>{t("four-monthly-sub-discounted-price")}</div>
              </div>
              <hr className="divider" />
              <ul className="benefits-list">
                <li>
                  <img src={CheckCircle} alt="Check Circle" />
                  {t("four-monthly-sub-benefit1")}
                </li>
                <li>
                  <img src={CheckCircle} alt="Check Circle" />
                  {t("four-monthly-sub-benefit2")}
                </li>
              </ul>
              {/* <div className="note">
                  <small>{t("four-monthly-sub-note")}</small>
                </div> */}
              <div className="button-container">
                <Button
                  onClick={() => {
                    onClickEnrollNow("fourMonth");
                  }}
                >
                  {t("Enroll Now")}
                  <img
                    src={ArrowRight}
                    alt="Arrow Right"
                    style={{ filter: "brightness(0) invert(1)" }}
                  />
                </Button>
              </div>
            </div>
          </div>
          <div className="contents-bottom">
            <div className="business-container">
              <div className="business-container-sub">
                <img
                  alt=""
                  loading="lazy"
                  width="40"
                  height="40"
                  decoding="async"
                  data-nimg="1"
                  src={Suitcase}
                  style={{ color: "transparent" }}
                />
                <div className="desc">
                  <h3>Udacity for Business</h3>
                  <p className="m-0">Buying for a business? Check out our business plans.</p>
                </div>
              </div>
              <a className="learn-more-button" href="/contact">
                Learn More
                <span>
                  <img src="/img/arrow-more.svg" alt="Arrow Right" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </StyledLectureDetailContainer>
      {/* footer block */}
      <Footer />
    </>
  );
}

const UdacityUpskilling = styled(Container)`
  height: auto;
  margin-top: 150px;
  @media (max-width: 768px) {
    height: auto;
  }
`;

const UdacityUpskillingTitle = styled(Container)`
  padding-left: 0;
  @media (max-width: 768px) {
    padding-top: 30px;
  }
`;

const UsButton = styled(Button)`
  background-color: #00c5a1;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  ustify-content: flex-start;
  color: black;
  align-items: center;
  &:hover {
    background-color: #047a65;
    border-color: #047a65;
    color: #ffffff;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  }
  margin-right: 10px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const UdacityPerformanceContents = styled(Container)`
  padding-bottom: 150px;
  @media (max-width: 768px) {
    padding-top: 30px;
  }
`;

const PriceSubscriptionContainer = styled(Container)`
  .partner-line {
    height: 2px;
    width: 100%;
    background-color: #c7c1c1;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .partner-container {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .partner-container > div {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
  }
  .partner-container img {
    flex: 0 0 auto;
    margin: 20px;
    width: 120px;
  }

  .review-container {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .review-container > div {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
  }
  .review-container img {
    flex: 0 0 auto;
    margin: 40px;
    width: 9%;

    @media (max-width: 1400px) {
      width: 15%;
    }
    @media (max-width: 676px) {
      width: 15%;
    }
  }
  .review-col-container > div {
    @media (max-width: 676px) {
    }
  }
  .review-button {
    @media (max-width: 676px) {
      font-size: 12px;
      gap: 23px;
    }
  }

  & {
    @media (min-width: 1400px) {
      max-width: 1140px;
    }
  }
`;

const AccountButton = styled(Button)`
  background-color: #2015ff;
  padding: 12px 24px;
  border: none;
  justify-content: flex-start;
  color: #fff;
  &:hover {
    background-color: rgb(23, 26, 83);
    transition: all 0.1s ease 0s;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 15px;
    margin-bottom: 32px;
  }
`;

const MainImage = styled.div`
  img {
    left: 75%;
  }
  @media (max-width: 991px) {
    width: 100%;
    height: 100%;
  }
  @media (min-width: 2200px) {
    img {
      left: 67%;
    }
  }
`;

const ContentImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
    height: "300px";
  }

  @media (max-width: 768px) {
    img {
      max-height: 400px;
      width: 100%;
    }
  }
`;

const ProjectImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
    height: "300px";
  }

  @media (max-width: 768px) {
    img {
      display: none;
    }
  }
`;

const ImageCaption = styled.div`
  text-align: center;
  color: #8f8f8f;
  border-top: 1px solid #8f8f8f;
  width: 50%;
  white-space: nowrap;
  display: flex;
  justify-content: center;

  &.first-caption {
    border-top: none;
  }

  @media (max-width: 1200px) {
    font-size: 0.8rem;
    width: 65%;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 410px) {
    font-size: 1rem;
    width: 90%;
  }
  .caption-logo-image {

  @media (max-width: 872px) {
      display: none;
  }
`;

const HeroBlock = ({ t }) => {
  const HeroContainer = styled(Container)`
    position: relative;
    padding: 0;
    margin: 0;
  `;

  return (
    <HeroContainer>
      <NavBar />
      <Container
        fluid
        className="position-absolute start-50 translate-middle mt-4 pt-5"
        style={{ top: -48 }}
      ></Container>
      <Container className="position-absolute top-50 start-50 translate-middle"></Container>
      <LectureDetail></LectureDetail>
    </HeroContainer>
  );
};

const BannerBox = styled.div`
  font-size: 3rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
  @media (max-width: 400px) {
    font-size: 1.5rem;
  }

  .multi-bg-box {
    background-image: url("/img/whyudacity/why_back.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 768px) {
      background-image: url("/img/business/business_background_img.webp");
      height: 430px !important;
    }

    @media (max-width: 1440px) {
      background-size: 1400px 490px;
      background-position: center;
    }
  }
`;

const ContentBox = styled.div`
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: #f0f5ff;

  .line {
    height: 140px;
    width: 2px;
    background-color: #fff;
  }

  .Korea-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .rectangle {
    height: 200px;
    width: calc(25% - 20px);
    padding: 30px;
  }
  .Korea-title {
    color: #2015ff;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 15px;
  }
  @media (max-width: 1199px) {
    .Korea-container {
      flex-direction: column;
    }
    .rectangle {
      width: calc(100% - 20px);
      margin-right: 0;
      position: relative;
      height: auto;
    }
    .line {
      height: 2px;
      width: 90%;
      background-color: #fff;
    }
    .Korea-title {
      font-size: 3rem;
    }
  }
`;
